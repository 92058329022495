import * as React from "react"
import { Link } from "gatsby"

const VideoBanner = ({video, full}) => {
  const videoPopup = () => {
    document.body.classList.add('popup-open')
    var full = document.getElementById('full-video');
    full.play();
  } 
  return (
  <>
    <div className='video--banner h-100vh bg-grey pos-rel flex flex-wrap'>
      <div className='video--play pos-rel z-12'>
        <div className='p20 pointer white small inline flex align-center m-mt5' onClick={videoPopup} role='presentation'>
          <span className='play-button mr10 m-mr5'/>Play <span className='m-hide ml5 mr5'>full-length</span> video
        </div>
      </div>
      <Menu />
      <video id='video' loop={true} playsInline={true} muted={true} autoPlay={true} className='bg-image br-80' allowFullScreen>
        <source src={video} />
      </video>
    </div>
    <VideoPopup video={full} />
  </>
  )
}

export default VideoBanner


const VideoPopup = ({video}) => {
  const closePopup = () => {
    document.body.classList.remove('popup-open')
    var full = document.getElementById('full-video');
    full.pause();
  } 
  return (
    <>
      <div className='video--popup'>
        <video id='full-video' loop={true}  autoPlay={false} controls={true} className='video' allowFullScreen>
          <source src={video} />
          <track kind="captions"></track> 
        </video>
      </div>  
      <div className='video--bg' role='presentation' onClick={closePopup}/> 
    </>
  )
} 

const Menu = () => {
  const storesClick = () => {
    window.scrollTo({ top: document.querySelector('.video--banner').offsetHeight, left: 0, behavior: "smooth" })
  }
  return (
    <div className='menu-links m-hide z-2 pos-rel w-100 mba p20'>
      <div className='max-1250 ma white h2 uppercase flex space-between text-center m-mb40'>
        <p className='link pointer m-h1 m0 m-ma' onClick={storesClick} role='presentation'>(Stores)</p>
        <Link className='link' to='/about'>(About)</Link>
        <Link className='link' to='/awards'>(Awards)</Link>
        <Link className='link' to='/news'>(News)</Link>
        <Link className='link' to='/about#gallery'>(Gallery)</Link>
        <Link className='link' to='/contact'>(Contact)</Link>
      </div>
    </div>
  )
}